import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
// import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
// import Hero from '../components/hub/hero/hero'
import CardSlider from '../components/hub/cardSlider/cardSlider'

import * as styles from '../components/globals-v2.module.css'

export const query = graphql`
    query TagTemplateQuery($slug: String!) {
        tag: sanityTag(slug: {current: {eq: $slug}}) {
            title
        }

        audio: allSanityAudio(filter: {tags: {elemMatch: {slug: {current: {eq: $slug}}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _type
                tags {
                title
                slug {
                    current
                }
                }
                title
                slug {
                current
                }
                featureImage {
                asset {
                    _id
                    url
                }
                }
                appleUrl
                spotifyUrl
            }
        }

        video: allSanityVideo(filter: {tags: {elemMatch: {slug: {current: {eq: $slug}}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _type
                tags {
                title
                slug {
                    current
                }
                }
                title
                slug {
                current
                }
                series {
                title
                }
                featureImage {
                asset {
                    _id
                    url
                }
                }
            }
        }

        written: allSanityWritten(filter: {tags: {elemMatch: {slug: {current: {eq: $slug}}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _type
                tags {
                    title
                    slug {
                        current
                    }
                }
                title
                blurb
                slug {
                    current
                }
                featureImage {
                    asset {
                        url
                        _id
                    }
                }
            }
        }
    }
`

const TagTemplate = props => {
    const { data, errors } = props
    const tag = data.tag;
    const writtenArticles = data.written.nodes;
    const audioPosts = data.audio.nodes;
    const videoPosts = data.video.nodes;
    
    return (
      <Layout hideContactBar={true} hideAnnouncements={true} logoOverrideUrl="/start/" darkMode={true} version="2">
        {/* {errors && <SEO title='GraphQL Error' />}
        {landing && <SEO title={landing.title || 'Untitled'} />}
  
        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )} */}
  
        <SEO title={`Tag: ${tag.title}`} />
  
        <div className={styles.backgroundControl}>
            <Container size="large">
                <div className={styles.tagHead}>
                    <div className={styles.tags}>
                        <span className={styles.headTitle}>Tags related to your search: </span>
                        <span className={styles.tag}>
                            {tag.title}
                        </span>
                    </div>
                    
                    <div className={styles.tagCounts}>
                        {writtenArticles.length > 0 && (<>{writtenArticles.length} Written, </>)}
                        {audioPosts.length > 0 && (<>{audioPosts.length} Audio, </>)}
                        {videoPosts.length > 0 && (<>{videoPosts.length} Video</>)}
                    </div>
                </div>

                {writtenArticles.length > 0 && (
                    <section className={`writtenArticles`}>
                        <h6 style={{margin: "0 0 -60px"}}>Written</h6>
                        <CardSlider type="simple" data={writtenArticles} settings={{slidesToShow: 3}} alternate={true} />
                    </section>
                )}

                {audioPosts.length > 0 && (
                    <section className={`audioPosts`}>
                        <h6 style={{margin: "0 0 -60px"}}>Audio</h6>
                        <CardSlider type="simple" data={audioPosts} settings={{slidesToShow: 3}} />
                    </section>
                )}

                {videoPosts.length > 0 && (
                    <section className={`videos`}>
                        <h6 style={{margin: "0 0 -60px"}}>Videos</h6>
                        <CardSlider type="simple" data={videoPosts} settings={{slidesToShow: 3}} alternate={true} />
                    </section>
                )}
            </Container>
        </div>
      </Layout>
    )
  }
  
  export default TagTemplate
  